import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBriefcase,
  faStore,
  faShop,
  faUserTie,
  faLandmarkDome,
  faBuilding,
  faFileCsv,
  faChevronUp,
  faUsers,
  faArrowTrendUp,
  faFilter,
  faPlus,
  faMinus,
  faCaretUp,
  faCaretDown,
  faMagnifyingGlass,
  faCode,
  faDownload,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faBookBookmark,
  faCopy,
  faCubesStacked,
  faChevronDown,
  faEnvelope,
  faLock,
  faCheck,
  faScaleUnbalanced,
  faBriefcaseMedical,
  faIndustry,
  faWrench,
  faCreditCard,
  faPersonDigging,
  faMicrochip,
  faBolt,
  faBasketShopping,
  faTowerCell,
  faUser,
  faBars,
  faUpLong,
  faDownLong,
  faCaretLeft,
  faCaretRight,
  faHouse,
  faRightLeft,
  faChartPie,
  faFileLines,
  faCalendar,
  faMagnifyingGlassChart,
  faChartSimple,
  faTable,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUpLong,
  faArrowUp,
  faArrowDown,
  faCubes,
  faMagnifyingGlassPlus,
  faRss,
  faTableCellsLarge,
  faClipboard,
  faNewspaper,
  faEllipsis,
  faList,
  faStar,
  faArrowRight,
  faBullhorn,
  faGlobe,
  faAtom,
  faPaperclip,
  faGear,
  faMountain,
  faExpand,
  faUpRightAndDownLeftFromCenter,
  faArrowTrendDown,
  faWandMagicSparkles,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faFacebookF, faGithub } from "@fortawesome/free-brands-svg-icons";

import { faClock } from "@fortawesome/free-regular-svg-icons";

library.add(
  faXmark,
  faList,
  faBars,
  faStar,
  faClipboard,
  faChevronLeft,
  faChevronRight,
  faFileLines,
  faCalendar,
  faClock,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faRightLeft,
  faChartPie,
  faHouse,
  faScaleUnbalanced,
  faBriefcaseMedical,
  faIndustry,
  faWrench,
  faCreditCard,
  faPersonDigging,
  faMicrochip,
  faBolt,
  faCubesStacked,
  faCubes,
  faEnvelope,
  faUser,
  faTableCellsLarge,
  faBars,
  faUpLong,
  faDownLong,
  faBasketShopping,
  faTowerCell,
  faCheck,
  faArrowUpLong,
  faBriefcase,
  faStore,
  faMagnifyingGlassPlus,
  faCaretLeft,
  faCaretRight,
  faShop,
  faUserTie,
  faLandmarkDome,
  faBuilding,
  faGoogle,
  faFacebookF,
  faFileCsv,
  faChevronUp,
  faUsers,
  faArrowTrendUp,
  faArrowTrendDown,
  faFilter,
  faPlus,
  faMinus,
  faCaretUp,
  faCaretDown,
  faMagnifyingGlass,
  faCode,
  faFileLines,
  faDownload,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faBookBookmark,
  faCopy,
  faChevronDown,
  faEnvelope,
  faLock,
  faMagnifyingGlassChart,
  faChartSimple,
  faTable,
  faRss,
  faNewspaper,
  faEllipsis,
  faBullhorn,
  faGlobe,
  faAtom,
  faPaperclip,
  faGear,
  faMountain,
  faExpand,
  faUpRightAndDownLeftFromCenter,
  faWandMagicSparkles,
  faFileExcel,
  faGithub
);
